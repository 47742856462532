<template>
  <div id="Glossary">
    <h1>Tulip's  Glossary</h1>
    <p>A</p>
    <p>B</p>
    <p>C</p>
  </div>

</template>

<script>

export default {
  name: 'Glossary',
  components: {
  },
  data(){
    return{
    }
  }
}
</script>
<style scoped>
#Glossary{
  color: #fff;
  margin: 2rem auto;
  width: 100%;
  min-height: 100vh;
}
.chain{
  color: #fff;
  font-size: 15px;
  transition: 1s;
  font-weight: bold;
  cursor:pointer;
}

</style>
